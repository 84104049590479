// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-mobile-footer-faq-js": () => import("./../../../src/components/Mobile/Footer/FAQ.js" /* webpackChunkName: "component---src-components-mobile-footer-faq-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-index-js": () => import("./../../../src/pages/mobile/index.js" /* webpackChunkName: "component---src-pages-mobile-index-js" */),
  "component---src-pages-mobile-policy-js": () => import("./../../../src/pages/mobile/policy.js" /* webpackChunkName: "component---src-pages-mobile-policy-js" */),
  "component---src-pages-mobile-terms-js": () => import("./../../../src/pages/mobile/terms.js" /* webpackChunkName: "component---src-pages-mobile-terms-js" */),
  "component---src-templates-category-app-js": () => import("./../../../src/templates/categoryApp.js" /* webpackChunkName: "component---src-templates-category-app-js" */),
  "component---src-templates-post-app-js": () => import("./../../../src/templates/postApp.js" /* webpackChunkName: "component---src-templates-post-app-js" */)
}

